/* eslint-disable jsx-a11y/no-autofocus */
import { memo, useCallback, useState } from "react";
import { useTranslation } from "@/i18n";

import {
  Box,
  Drawer,
  Grid,
  IconArrowLeft,
  Spacer,
  useDrawer,
  useDrawerActions,
} from "@plum/plum-ui-next";

import { IconStack, IconPerson, IconScribble1 } from "@plum/ui-icons";

import { GuestsState } from "@/types/search";
import { useGuestString } from "@/hooks/useGuestString";

import { InputPreview } from "../InputPreview";
import { SFooterWrap } from "../DatesFilter/Form.styles";

import { GuestFilterGroup } from "./GuestFilterGroup";
import { Button, Text } from "@plum/ui-core";
import { MobileFilterDrawer } from "../MobileFilterDrawer/MobileFilterDrawer";
interface GuestMobileFilterProps {
  initialValue: GuestsState;
  dialogRef: React.RefObject<HTMLDialogElement>;
  onConfirm: (guests: GuestsState) => void;
}

function GuestFilterFooter({ onConfirm, isDesktop, isDirty }) {
  const { t } = useTranslation();

  return (
    <>
      <div className={SFooterWrap({ divider: true }) + " items-center justify-end"}>
        {!isDesktop && (
          <>
            <Button
              type="button"
              className="px-12 rounded-full"
              disabled={!isDirty}
              testId="apply-guests"
              onClick={() => {
                onConfirm();
              }}
            >
              {t("common:applyButton")}
            </Button>
          </>
        )}
      </div>
    </>
  );
}

export const GuestMobileFilter = memo(function ({
  initialValue,
  onConfirm,
  dialogRef,
}: GuestMobileFilterProps) {
  const { t } = useTranslation();
  const guestString = useGuestString(initialValue);

  const [willClose, setWillClose] = useState(false);

  const [localState, setLocalState] = useState<GuestsState>(initialValue);

  const handleChange = (category: string, value: number) => {
    setLocalState({ ...localState, [category]: value });
  };

  const handleClose = useCallback(async () => {
    setWillClose(true);
    await setTimeout(() => {
      dialogRef.current?.close();
      setWillClose(false);
    }, 380);
  }, [dialogRef]);

  return (
    <>
      <InputPreview
        figure={
          <IconStack>
            <IconScribble1 />
            <IconPerson className="mr-4" />
          </IconStack>
        }
        value={guestString}
        dataTestid="guest-filter-mobile"
        onClick={() => dialogRef.current?.showModal()}
      />

      <MobileFilterDrawer dialogRef={dialogRef} handleClose={handleClose} willClose={willClose}>
        <>
          <div autoFocus tabIndex={-1}></div>
          <Grid css={{ height: "92.5%" }} rows="1fr min-content" placeholder="">
            <Box css={{ paddingX: "$32" }}>
              <Text as="h3" _scale="b-medium">
                {t("common:guestsPlaceholder")}
              </Text>
              <Spacer size={24} />
              <GuestFilterGroup isDesktop={false} guests={localState} onChange={handleChange} />
            </Box>
            <GuestFilterFooter
              isDesktop={false}
              onConfirm={() => {
                onConfirm(localState);
                handleClose();
              }}
              isDirty={JSON.stringify(initialValue) !== JSON.stringify(localState)}
            />
          </Grid>
        </>
      </MobileFilterDrawer>
    </>
  );
});

GuestMobileFilter.displayName = "GuestMobileFilter";
