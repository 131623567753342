"use client";

import { useRef } from "react";

import { IParsedPointOfInterest } from "@plum/plum-contentful/content-types/content/parsers";

import { useCarousel } from "@plum/ui-carousel";
import { Image, Text } from "@plum/ui-core";
import { IconChevron } from "@plum/ui-icons";

export interface IBookmark {
  title: string;
  bannerImage: string;
  bannerImageAlt: string;
  linkUrl: string;
  subTitle?: string;
}

export interface ISearchBookmarksProps {
  items: IParsedPointOfInterest[];
}

export const SearchBookmarks: React.FC<React.PropsWithChildren<ISearchBookmarksProps>> = ({
  items,
}) => {
  const prevButtonRef = useRef<HTMLButtonElement>(null);
  const nextButtonRef = useRef<HTMLButtonElement>(null);

  const [emblaRef, emblaApi] = useCarousel({
    options: {
      align: "start",
      dragFree: true,
      containScroll: "trimSnaps",
    },
    events: {
      scroll: (emblaApi) => {
        prevButtonRef.current!.disabled = !emblaApi.canScrollPrev();
        nextButtonRef.current!.disabled = !emblaApi.canScrollNext();
      },
    },
  });

  return (
    <section className="overflow-x-hidden py-16">
      <div className="container container-narrow">
        <div className="flex items-center gap-8">
          <button
            ref={prevButtonRef}
            className="mb-16 focus-ring max-sm:hidden transition disabled:opacity-25"
            type="button"
            onClick={() => emblaApi?.scrollPrev()}
          >
            <IconChevron _block _rotate={90} />
          </button>

          <div ref={emblaRef} className="w-full sm:overflow-x-hidden">
            <div className="flex gap-4 sm:gap-8">
              {items.map((bk, idx) => (
                <a
                  key={idx}
                  className="flex-none w-[12rem] sm:w-[15rem] focus-ring"
                  href={bk.linkUrl}
                >
                  <Image
                    className="mb-4 rounded-xs"
                    src={`${bk.bannerImage}?q=80`}
                    alt={bk.bannerImage}
                    width={240}
                    layout="constrained"
                    cdn="contentful"
                    aspectRatio={4 / 5}
                  />

                  <Text as="p" className="text-center" _scale="b-small" _bold>
                    {bk.title}
                  </Text>
                </a>
              ))}
            </div>
          </div>

          <button
            ref={nextButtonRef}
            className="mb-16 focus-ring max-sm:hidden transition disabled:opacity-25"
            type="button"
            onClick={() => emblaApi?.scrollNext()}
          >
            <IconChevron _block _rotate={270} />
          </button>
        </div>
      </div>
    </section>
  );
};
