import { useRef } from "react";
import { cnBase } from "tailwind-variants";

import { ProductRailData } from "@plum/plum-ui-next";
import { Text, ListingCard } from "@plum/ui-core";
import { ListingRail } from "@plum/ui-content";
import { UseCarouselEventCallback } from "@plum/ui-carousel";

import { IListingSection } from "@/pages/api/d/[destination]";

import { GetSearchResults_search_defaultResults_results_listing } from "@/gql/types/GetSearchResults";

export interface IProductRailContainerData extends ProductRailData, IListingSection {
  imageAlt?: string;
  linkText?: string;
  linkUrl?: string;
  linkTrackId?: string;
  linkTrackValue?: string;
  listingTrackId?: string;
  name?: string;
}

interface IProductRailContainerProps {
  data: IProductRailContainerData;
  listings?: GetSearchResults_search_defaultResults_results_listing[];
  isLoading?: boolean;
}

export const ProductRailContainer: React.FC<
  React.PropsWithChildren<IProductRailContainerProps>
> = ({ data, isLoading = false, listings = [] }) => {
  const infoRef = useRef<HTMLDivElement>(null);

  const updateInfoOpacity: UseCarouselEventCallback = (emblaApi) => {
    const engine = emblaApi.internalEngine();

    const progress = emblaApi.scrollProgress() * 100;

    const max = engine.limit.length;

    const progressInPx = (progress * max) / 100;

    const opacity = Math.max(0, Math.min(1, 1 - progressInPx / 200));

    infoRef.current?.style.setProperty("--rail-info-opacity", String(opacity));
  };

  return (
    <section className="overflow-x-hidden pt-10 sm:pt-12 lg:pt-20">
      <div
        className={cnBase(
          "container grid grid-cols-1 gap-4",
          data.isSplit ? "md:grid-cols-[320px_minmax(0,1fr)]" : ""
        )}
      >
        <div ref={infoRef} className="md:opacity-[--rail-info-opacity]">
          {data.preTitle && (
            <Text className="mb-2" _scale="b-small">
              {data.preTitle}
            </Text>
          )}
          <Text as="h2" _scale={data.preTitle ? "h-5" : "h-4"} _serif _bold>
            {data.title}
          </Text>
          {data.copy && (
            <Text className="mt-2 text-gray-700" _scale="b-medium">
              {data.copy}
            </Text>
          )}
        </div>

        <ListingRail onScroll={data.isSplit ? updateInfoOpacity : undefined}>
          {listings.map((listing) => (
            <ListingCard
              key={listing.listingId}
              name={listing.name}
              imageSrc={listing.featuredImageUrl!}
              imageAlt={listing.name}
              locations={
                listing
                  .shortLocation!.split(",")
                  .reverse()
                  .map((v) => v.trim()) as [string, string]
              }
              _variant="portrait"
              url={listing.url as string}
            />
          ))}
        </ListingRail>
      </div>
    </section>
  );
};
