import { tv } from "tailwind-variants";

export const SFlexibleCheckIn = tv({
  base: [
    "flex",
    "border-b",
    "border-b-gray-150",
    "px-6",
    "py-4",
    "gap-2",
    "overflow-x-auto",
    "md:px-0",
    "md:border-b-0",
  ],
  variants: {
    inline: {
      true: ["border-0", "px-0", "justify-between", "max-w-[340px]"],
    },
  },
});
