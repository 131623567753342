import * as React from "react";

import { Divider } from "@plum/ui-core";

import { cnBase } from "tailwind-variants";

interface ISectionProps {
  children: React.ReactNode;

  compact?: boolean;
  divider?: boolean;
  className?: string;
}

export const PageSection = ({
  children,
  compact = false,
  divider = false,
  className,
}: ISectionProps) => {
  return (
    <section data-testid="section" className={className}>
      {divider && <Divider className="mb-10 sm:mb-12" />}

      <div className={cnBase(!compact ? "mb-12 sm:mb-14" : "")} data-testid="section-content">
        {children}
      </div>
    </section>
  );
};

PageSection.displayName = "PageSection";
