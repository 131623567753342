import { Fragment } from "react";
import { useTranslation } from "@/i18n";
import { ComboboxGroup } from "./Group";

import {
  ComboboxItemFormat,
  HiddenVisually,
  IconClock,
  IconDots,
  IconLocationPin,
  IconScribble,
  IconStack,
  Spacer,
} from "@plum/plum-ui-next";
import { Text } from "@plum/ui-core";
import { SDestinationComboboxItem } from "./DestinationCombobox.styles";
import { TComboboxSection } from "./types";
import { ComboboxMenu } from "./Menu";
import { ComboboxItem } from "./Item";

export function DestinationComboboxMenu({ sections }: { sections: TComboboxSection[] }) {
  return (
    <ComboboxMenu className="mt-4 md:mt-0">
      {sections.map((section, index, arr) => (
        <DestinationSection
          key={section.name}
          name={section.name}
          items={section.items}
          loading={section.loading}
          hiddenLabel={section.name === "suggested"}
          indexOffset={arr?.[index - 1]?.items.length}
        />
      ))}
    </ComboboxMenu>
  );
}

interface DestinationSectionProps {
  name: string;
  items: ComboboxItemFormat[];

  indexOffset?: number;
  hiddenLabel?: boolean;

  loading?: boolean;
}

function DestinationSection({
  name,
  items,
  hiddenLabel,
  indexOffset = 0,
  loading,
}: DestinationSectionProps) {
  const { t } = useTranslation();

  if (!items.length && !loading) return null;

  const LabelOuter = hiddenLabel ? HiddenVisually : Fragment;

  return (
    <div className="mb-6">
      <LabelOuter>
        <Text id={`destination-group-${name}`} as="h6" _scale="b-small">
          {t(`common:destinations.${name}`)}
        </Text>
      </LabelOuter>
      {!hiddenLabel && <Spacer size={8} />}
      <ComboboxGroup labelledBy={`destination-group-${name}`}>
        {loading ? (
          <div className="justify-center py-2">
            <IconDots />
          </div>
        ) : (
          items.map((item, index) => (
            <ComboboxItem
              key={`${item.value}-${index}`}
              index={index + indexOffset}
              item={item}
              className={SDestinationComboboxItem()}
            >
              <button tabIndex={-1} type="button" className="flex text-left cursor-pointer">
                <IconStack>
                  <IconScribble variant={2} />
                  {name === "recent" ? <IconClock /> : <IconLocationPin />}
                </IconStack>
                <Spacer size={8} horizontal />
                <Text color="neutrals-9">{item.label}</Text>
              </button>
            </ComboboxItem>
          ))
        )}
      </ComboboxGroup>
    </div>
  );
}
