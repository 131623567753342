"use client";

import { useTranslation } from "@/i18n";

import { Picture, Text } from "@plum/ui-core";

import { useCityTranslation } from "@/hooks/useCityTranslation";

import { SearchBar } from "../SearchBar";

import * as styles from "./Hero.styles";

type HeroProps = {
  image?: string;

  title: string;
  subTitle?: string;
};

export function Hero({
  title,
  subTitle,
  image = "https://plum-live-storage.imgix.net/img/homepage/hero/hero-test.png",
}: HeroProps) {
  const { t } = useTranslation();

  const { tProps, tCustom } = useCityTranslation();

  const s = styles.hero();
  return (
    <section className={s.root()}>
      <div className={s.image()}>
        <Picture
          src={image.includes("http") ? image : "https://" + image}
          alt={t("landingpages:heroSection.altText", tProps)}
          width={3840}
          layout="original"
          breakpoints={[360, 720, 1080, 1440, 1920]}
          cdn={!image ? "imgix" : "contentful"}
          priority={true}
        />
      </div>
      <div className={s.overlay()}>
        <div className={s.content()}>
          <div className="text-center [text-wrap:balance] mb-6">
            <Text
              as="h1"
              className="text-gray-900 md:text-white"
              _scale="h-3"
              _serif
              _bold
              data-testid="hero-title"
            >
              {tCustom(title)}
            </Text>

            {subTitle && (
              <Text
                as="h3"
                className="text-gray-100 tracking-wider uppercase mt-4 md:hidden"
                _scale="b-small"
                _bold
                data-testid="hero-subtitle"
              >
                {tCustom(subTitle)}
              </Text>
            )}
          </div>

          <SearchBar />
        </div>
      </div>
    </section>
  );
}
