import urljoin from "url-join";
import qs from "query-string";
import { env } from "@/env.mjs";

import { GetSearchResults_search_defaultResults_results_listing } from "@/gql/types/GetSearchResults";

/**
 * Fetch listings data based on a list of listing ids
 *
 * Example: Fetch listings data based on listing references on contentful
 */
export const fetchListings = async (
  listingIds: number[]
): Promise<GetSearchResults_search_defaultResults_results_listing[]> => {
  try {
    if (!listingIds.length) {
      return [];
    }

    const queryStr = qs.stringify({ ids: listingIds });

    //TODO: Call /public/default to retrive default listings (no need to pass listingIds)
    const response = await fetch(
      urljoin(env.NEXT_PUBLIC_API_PATH, "listings", `?${queryStr}&getLocation=true`)
    );
    if (response.ok) {
      const data = await response.json();

      return data;
    }
  } catch (e) {
    console.error(`API - fetchListings`, e);
  }

  return [];
};

interface IListingResponse {
  listingId: number;
  name: string;
  featuredImageUrl: string;
  url: string;
  location: string;
  bedroomCount: number;
  bathroomCount: number;
  guestCount: number;
  latitude: number;
  longitude: number;
  description: string;
}
export const getListingsData = async (
  listingIds: number[]
): Promise<GetSearchResults_search_defaultResults_results_listing[]> => {
  try {
    const queryStr = qs.stringify({ ids: listingIds });

    const url = urljoin(
      env.NEXT_PUBLIC_API_LISTINGS_URL,
      "getbyids",
      `?${queryStr}&getLocation=true`
    );

    const res = await fetch(url);
    const data = await res.json();
    const listingsData = listingIds
      .map((listingId) => data.listings[listingId])
      .filter(Boolean)
      .map((listing: GetSearchResults_search_defaultResults_results_listing) => {
        return {
          __typename: "Listing",
          listingId: listing.listingId,
          name: listing.name,
          featuredImageUrl: listing.featuredImageUrl,
          url: listing.url,
          shortLocation: listing.location,
          bedroomCount: listing.bedroomCount,
          guestCount: listing.guestCount,
          bathroomCount: listing.bathroomCount,
          latitude: listing.latitude,
          longitude: listing.longitude,
          description: listing.description,
        } as GetSearchResults_search_defaultResults_results_listing;
      });

    return listingsData;
  } catch (error) {
    console.error(error);
  }

  return [];
};

export interface IListingPrices {
  [index: number]: number;
}

export const fetchListingPrices = async (currencyId: number, listingIds: number[]) => {
  const response = await fetch(urljoin(env.NEXT_PUBLIC_API_PATH, "prices"), {
    method: "POST",
    body: JSON.stringify({
      listingIds,
      currencyId,
    }),
  })
    .then((response) => {
      if (response.status === 200) {
        return response.json();
      }
    })
    .then((data) => {
      // Get object down to key values of id and price
      const listingPrices: IListingPrices = {};
      Object.keys(data).forEach((key) => {
        listingPrices[key] = data[key]["price"];
      });

      return listingPrices;
    });

  return response;
};
