import { useTranslation } from "@/i18n";
import { parseISO } from "date-fns";

import { Spacer } from "@plum/plum-ui-next";

import { DateFormats, formatDate } from "@/utils/dates";
import { DatepickerType, DatesState, FlexibleState } from "@/types/search";
import { useHasCalendarDates, useHasFormFlexibleDates } from "@/hooks/useHasDates";
import { Text } from "@plum/ui-core";
import { SFlexibleDatesPill } from "../FlexibleDatesPill";

interface DateCopyProps {
  datepickerType: DatepickerType;
  dates: DatesState;
}

export function useFlexibleStateToCopy() {
  const { t } = useTranslation();

  return (flexibleState: FlexibleState) => {
    if (!flexibleState || flexibleState.months?.length === 0 || !flexibleState.period) return;

    return t(`common:flexibleDates.period.options.${flexibleState.period}.preview`, {
      months: flexibleState?.months
        .map((month) => (month ? formatDate(parseISO(month), DateFormats.MONTH_SHORT) : null))
        .filter(Boolean)
        .join(", "),
    });
  };
}

export const DateCopy = ({ dates, datepickerType }: DateCopyProps) => {
  const { t } = useTranslation();

  const hasDates = useHasCalendarDates({
    datepickerType,
    checkIn: dates.calendar.checkIn,
    checkOut: dates.calendar.checkOut,
  });
  const hasFlexible = useHasFormFlexibleDates({
    datepickerType,
    period: dates.flexible?.period,
    months: dates.flexible?.months,
  });

  const flexibleCopy = useFlexibleStateToCopy();

  const flexibleString = hasFlexible ? flexibleCopy(dates.flexible) : "";

  return (
    <Text
      color="neutrals-9"
      _scale="b-small"
      className="whitespace-nowrap overflow-hidden text-ellipsis"
    >
      {hasDates ? (
        <>
          <span>
            {formatDate(dates.calendar.checkIn as Date, DateFormats.PRETTY_NO_YEAR)}
            {" - "}
            {formatDate(dates.calendar.checkOut as Date, DateFormats.PRETTY_NO_YEAR)}
          </span>
          {dates.calendar.flex && (
            <>
              <Spacer horizontal inline size={4} />
              <SFlexibleDatesPill>±{dates.calendar.flex}</SFlexibleDatesPill>
            </>
          )}
        </>
      ) : hasFlexible ? (
        <span>{flexibleString}</span>
      ) : (
        t("common:addDates")
      )}
    </Text>
  );
};
