import { tv } from "tailwind-variants";

export const SFlexibleDatesPeriodOption = tv({
  base: [
    "flex",
    "items-center",
    "justify-center",
    "flex-col",
    "bg-neutral-0",
    "border",
    "border-gray-100",
    "rounded-md",
    "p-4",
    "cursor-pointer",
    "transition-all",
    "duration-150",
    "ease-out",
    "hover:bg-neutral-1",
  ],
  variants: {
    selected: {
      true: ["bg-yellow-lightest", "border-yellow-light", "hover:bg-yellow-lighter"],
    },
  },
});
