import { forwardRef } from "react";
import { Spacer } from "@plum/plum-ui-next";
import { Text } from "@plum/ui-core";
import { SInputPreview } from "./InputPreview.styles";

export interface InputPreviewProps {
  figure: React.ReactNode;
  value: string | React.ReactNode | null;
  placeholder?: string;
  onClick?: () => void;
  dataTestid?: string;
  className?: string;
}

export const InputPreview = forwardRef<HTMLButtonElement, InputPreviewProps>(
  ({ figure, value, placeholder, onClick, dataTestid, className }, ref) => {
    return (
      <button
        ref={ref}
        type="button"
        onClick={onClick}
        data-testid={dataTestid}
        className={SInputPreview({ className })}
        heap-ignore="true"
      >
        {figure}

        <Text
          _scale="b-small"
          color="neutrals-9"
          _bold
          className="whitespace-nowrap overflow-hidden text-ellipsis"
        >
          {value || placeholder}
        </Text>
      </button>
    );
  }
);

InputPreview.displayName = "InputPreview";
