"use client";

import { useState, useMemo, useEffect } from "react";

import { fetchListings } from "@/services/listings-service";

import { PageSection } from "@/components/pageSection";

import { ProductRailContainer, IProductRailContainerData } from "@/components/ProductRailContainer";
import { GetSearchResults_search_defaultResults_results_listing } from "@/gql/types/GetSearchResults";

interface IProductRailSectionProps {
  data: IProductRailContainerData;

  divider?: boolean;
}

export const ProductRailSection: React.FC<React.PropsWithChildren<IProductRailSectionProps>> = ({
  data = { items: [] },
  divider = true,
}) => {
  const [state, setState] = useState<{
    listings: GetSearchResults_search_defaultResults_results_listing[];
    isLoading: boolean;
  }>({
    listings: [],
    isLoading: true,
  });

  // Get all listings ids (memoized for perf)
  const listingIds = useMemo(() => data.items?.map((listing) => listing.id) || [], [data.items]);

  useEffect(() => {
    const handleFetchListings = async () => {
      setState((s) => ({ ...s, isLoading: true }));

      // Fetch more data for listings that were added on CMS (contentful)
      const listings = await fetchListings(listingIds);

      // Merge original array of listings with new data
      setState({
        listings: listings.filter((listing) => listing.featuredImageUrl),
        isLoading: false,
      });
    };

    handleFetchListings();
  }, [listingIds]);

  return (
    <PageSection divider={divider}>
      <ProductRailContainer data={data} isLoading={state.isLoading} listings={state.listings} />
    </PageSection>
  );
};
