import { tv } from "tailwind-variants";

export const SFlexibleDatesMonthsOption = tv({
  base: [
    "flex",
    "items-center",
    "justify-center",
    "flex-col",
    "bg-white",
    "border",
    "border-gray-100",
    "rounded-md",
    "px-4",
    "py-10",
    "cursor-pointer",
    "transition-all",
    "duration-150",
    "ease-out",
    "hover:bg-gray-50",
    "md:w-[108px]",
  ],
  variants: {
    selected: {
      true: ["bg-yellow-lightest", "border-yellow-light", "hover:bg-yellow-lighter"],
    },
    disabled: {
      true: ["opacity-50", "pointer-events-none"],
    },
  },
});
