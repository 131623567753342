import { useEffect, useMemo, useRef, useState } from "react";
import { useLazyQuery, useQuery } from "@apollo/client";
import { useCombobox } from "downshift";

import { ComboboxItemFormat, useIdleCallbackEffect } from "@plum/plum-ui-next";

import { GET_POPULAR_SUGGESTIONS, GET_SUGGESTIONS } from "@/gql/destinations";
import {
  GetPopularSuggestions,
  GetPopularSuggestionsVariables,
  GetPopularSuggestions_popularSuggestionsByCountryCode_places,
} from "@/gql/types/GetPopularSuggestions";
import {
  GetSuggestions,
  GetSuggestionsVariables,
  GetSuggestions_suggestionsByTerm_places,
} from "@/gql/types/GetSuggestions";

import { useUserContext } from "@/data/UserProvider";

import { useRecentLocations } from "@/hooks/useLocalStorage";

import { TComboboxSection } from "./types";

function isInputSet(value: string) {
  const trimmedValue = value?.trim();

  return !!trimmedValue && trimmedValue.length >= 2;
}

type UseDestinationComboboxArguments = {
  isOpen: boolean;
  initialValue: string;
  inputId: string;
  onSelect: (label: string, value: string) => void;
};

export function useDestinationCombobox({
  isOpen = false,
  initialValue,
  inputId,
  onSelect,
}: Partial<UseDestinationComboboxArguments>) {
  const clearButtonRef = useRef<HTMLButtonElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const [inputValue, setInputValue] = useState(initialValue || "");

  const suggestedDestinations = useSuggestions(inputValue);
  const popularDestinations = usePopularDestinations();

  const [recentLocations] = useRecentLocations();

  const showSuggestions = useMemo(
    () => isInputSet(inputValue) && suggestedDestinations.items.length > 0,
    [inputValue, suggestedDestinations]
  );

  const comboboxSections = useMemo(() => {
    const sections: TComboboxSection[] = [];

    if (!showSuggestions) {
      sections.push({
        name: "recent",
        items: recentLocations,
        loading: false,
      });

      if (recentLocations.length <= 3) {
        sections.push({
          name: "popular",
          items: popularDestinations.items.slice(
            0,
            popularDestinations.items.length - recentLocations.length
          ),
          loading: popularDestinations.loading,
        });
      }
    } else {
      sections.push({
        name: "suggested",
        items: suggestedDestinations.items,
        loading: suggestedDestinations.loading,
      });
    }

    return sections;
  }, [
    showSuggestions,
    popularDestinations.items,
    popularDestinations.loading,
    recentLocations,
    suggestedDestinations.items,
    suggestedDestinations.loading,
  ]);

  const comboboxItems = useMemo(
    () => comboboxSections.flatMap((section) => section.items),
    [comboboxSections]
  );

  const comboboxProps = useCombobox({
    inputValue,
    isOpen,
    items: comboboxItems,
    inputId,
    itemToString: (item) => item?.label || "",
    onInputValueChange: ({ inputValue }) => {
      setInputValue(inputValue || "");
    },
    onSelectedItemChange: ({ selectedItem }) => {
      if (!selectedItem) return;

      onSelect?.(selectedItem.label, selectedItem.value);
      setInputValue(selectedItem.label);
    },
  });

  function clearSelection() {
    comboboxProps.setInputValue("");
    comboboxProps.selectItem(null as unknown as ComboboxItemFormat);

    inputRef.current?.focus();
  }

  useEffect(() => {
    if (initialValue === inputValue) return;

    setInputValue(initialValue || "");
  }, [initialValue]);

  return {
    clearButtonRef,
    inputRef,
    inputValue,

    comboboxSections,
    comboboxItems,
    comboboxProps,

    clearSelection,
  };
}

function useSuggestions(term: string) {
  const { data, previousData, loading } = useQuery<GetSuggestions, GetSuggestionsVariables>(
    GET_SUGGESTIONS,
    {
      variables: { term },
      skip: !isInputSet(term),
    }
  );

  const items = useMemo(
    () =>
      (
        (loading ? previousData?.suggestionsByTerm?.places : data?.suggestionsByTerm?.places) || []
      ).map((item: GetSuggestions_suggestionsByTerm_places) => mapDestinationToComboboxItem(item)),
    [data, previousData, loading]
  );

  return {
    items,
    loading,
  };
}

function usePopularDestinations() {
  const [{ isFetching, user }] = useUserContext();

  const [getPopularSuggestions, { data, loading }] = useLazyQuery<
    GetPopularSuggestions,
    GetPopularSuggestionsVariables
  >(GET_POPULAR_SUGGESTIONS, {});

  useIdleCallbackEffect(
    (onIdle) => {
      if (isFetching) return;

      onIdle(() => {
        getPopularSuggestions({
          variables: {
            countryCode: user.countryCode,
          },
        });
      });
    },
    [getPopularSuggestions, isFetching, user.countryCode]
  );

  const items = useMemo(
    () => (data?.popularSuggestionsByCountryCode?.places || []).map(mapDestinationToComboboxItem),
    [data]
  );

  return {
    items,
    loading,
  };
}

function mapDestinationToComboboxItem(
  item:
    | GetSuggestions_suggestionsByTerm_places
    | GetPopularSuggestions_popularSuggestionsByCountryCode_places
): ComboboxItemFormat {
  return {
    label: item.title,
    value: item.placeId,
  };
}
