import { tv } from "tailwind-variants";

export const SInputPreview = tv({
  base: [
    "flex",
    "w-full",
    "items-center",
    "border",
    "border-gray-150",
    "py-3",
    "px-4",
    "cursor-pointer",
    "hover:bg-gray-50",
    "md:border-none",
    "md:w-auto",
    "md:p-0",
  ],
});
