import { useFormContext } from "react-hook-form";
import { useTranslation } from "@/i18n";

import { Pill } from "@plum/ui-core";
import { DatesFilterFormState } from "../../types";
import { SFlexibleCheckIn } from "./FlexibleCheckIn.styles";
/**
 * Array of allowed plus/minus days from selected check-in
 */
const checkInPlusMinusLengths = [null, 1, 2, 3, 7];

export function FlexibleCheckIn() {
  const { t } = useTranslation();
  const { watch, setValue } = useFormContext<DatesFilterFormState>();

  const flex = watch("calendar.flex");

  const handleClick = (length: number | null) => {
    const newLength = length !== flex ? length : null;

    setValue("calendar.flex", newLength);
  };

  return (
    <div className={SFlexibleCheckIn({ className: "items-center justify-start" })}>
      {checkInPlusMinusLengths.map((length) => (
        <Pill
          key={length}
          className="flex-none"
          _shape="squared"
          _size="small"
          _state={flex === length ? "selected" : undefined}
          testId="flexible-days"
          onClick={() => handleClick(length)}
        >
          {length ? (
            <>
              {" "}
              ± {length} {t("common:day", { count: length })}
            </>
          ) : (
            t("common:exactDates")
          )}
        </Pill>
      ))}
    </div>
  );
}
