import { tv } from "tailwind-variants";

export const SPopoverTriggerButton = tv({
  base: "w-full h-full items-center flex px-4 cursor-pointer overflow-auto text-ellipsis whitespace-nowrap",
});

export const SPopoverTriggerButtonWithSearch = tv({
  base: [
    "grid",
    "items-center",
    "grid-cols-[4fr,auto]",
    "h-full",
    "w-full",
    "pl-4",
    "pr-2",
    "focus-within:outline-none",
    "focus-within:bg-gray-50",
  ],
  variants: {
    active: {
      true: ["outline-none", "bg-gray-50"],
    },
  },
});
