import { memo, useCallback, useState } from "react";

import { Text, Popover } from "@plum/ui-core";
import { GuestsState } from "@/types/search";

import { useGuestString } from "@/hooks/useGuestString";

import { GuestFilterGroup } from "../GuestMobileFilter/GuestFilterGroup";

import { SPopoverTriggerButton, SPopoverTriggerButtonWithSearch } from "./Popovers.styles";
import useDetectRefClick from "@/hooks/useDetectRefClick";

interface IDesktopGuestFilterProps {
  guests: GuestsState;
  onChange: (category: keyof GuestsState, value: number) => void;
}

export const GuestsPopover = memo(({ guests, onChange }: IDesktopGuestFilterProps) => {
  const [isOpen, setIsOpen] = useState(false);

  const { ref: inputRef, isClick: isInputClick } = useDetectRefClick<HTMLInputElement>();

  const guestString = useGuestString(guests);
  const expandText = Object.values(guests).reduce((a, b) => a + b) > guests.adults ? "..." : "";

  const handleInteractOutside = useCallback(
    (e) => {
      if (isInputClick(e)) {
        return;
      }
      return setIsOpen(false);
    },
    [isInputClick]
  );

  const handleOpen = useCallback(() => {
    !isOpen && setIsOpen(true);
  }, [isOpen]);

  return (
    <Popover.Root open={isOpen}>
      <div className={SPopoverTriggerButtonWithSearch({ active: isOpen })}>
        <Popover.Trigger asChild>
          <button
            className={SPopoverTriggerButton({ className: "w-auto p-0" })}
            type="button"
            ref={inputRef as React.RefObject<HTMLButtonElement>}
            data-testid="desktop-guest-filter"
            onFocus={handleOpen}
            onClick={handleOpen}
            heap-ignore="true"
          >
            <Text color="neutrals-9" _scale="b-small" className="overflow-hidden text-ellipsis">
              {isOpen ? guestString : guestString.split(",").reverse().pop() + expandText}
            </Text>
          </button>
        </Popover.Trigger>
      </div>
      <Popover.Content
        avoidCollisions={false}
        align="start"
        className="w-[296px]"
        onInteractOutside={handleInteractOutside}
      >
        <GuestFilterGroup guests={guests} onChange={onChange} />
      </Popover.Content>
    </Popover.Root>
  );
});

GuestsPopover.displayName = "GuestsPopover";
