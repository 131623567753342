import { useTranslation } from "@/i18n";

import { GuestsState } from "@/types/search";

export const useGuestString = (guests: GuestsState) => {
  const { t } = useTranslation();

  const groupedCategories = {
    guest: (guests.adults || 0) + (guests.children || 0),
    infant: guests.infants || 0,
    pet: guests.pets || 0,
  };

  const relevantCategories = Object.keys(groupedCategories).filter(
    (category) => groupedCategories[category] > 0
  );

  if (relevantCategories.length === 0) {
    return t("common:addGuests");
  }

  return relevantCategories
    .map((category) =>
      t(`common:${category}Amount`, {
        count: groupedCategories[category],
      })
    )
    .join(", ");
};
