import { useTranslation } from "@/i18n";
import { useFormContext } from "react-hook-form";

import { DatesFilter } from "../DatesFilter";
import { LocationFilter } from "../LocationFilter";
import { GuestMobileFilter } from "../GuestMobileFilter";

import { IPrimaryFiltersFormValues } from ".";
import { DatepickerType } from "@/types/search";
import { Button } from "@plum/ui-core";
import { useCallback, useRef } from "react";

export const MobileFilters = () => {
  const { t } = useTranslation();

  const { setValue, watch } = useFormContext<IPrimaryFiltersFormValues>();

  const { destination, dates, guests, datepickerType } = watch();

  const handleLocationChange = useCallback(
    (destination) => {
      setValue("destination", destination);
    },
    [setValue]
  );

  const handleDatesChange = useCallback(
    ({ calendar, flexible, type }) => {
      setValue("dates.calendar", calendar);
      setValue("dates.flexible", flexible);
      setValue("datepickerType", type);
    },
    [setValue]
  );

  const handleGuestsChange = useCallback(
    (guests) => {
      setValue("guests", guests);
    },
    [setValue]
  );
  const locationDialogRef = useRef<HTMLDialogElement>(null);
  const datesDialogRef = useRef<HTMLDialogElement>(null);
  const guestsDialogRef = useRef<HTMLDialogElement>(null);

  return (
    <div className="flex flex-col space-y-6">
      <div className="flex flex-col space-y-4">
        <LocationFilter
          dialogRef={locationDialogRef}
          initialValue={destination}
          onChange={handleLocationChange}
        />
        <DatesFilter
          dialogRef={datesDialogRef}
          initialValue={{
            calendar: dates.calendar,
            flexible: dates.flexible,
            type: datepickerType as DatepickerType,
          }}
          onChange={handleDatesChange}
        />

        <GuestMobileFilter
          initialValue={guests}
          onConfirm={handleGuestsChange}
          dialogRef={guestsDialogRef}
        />
      </div>
      <Button type="submit" className="w-full" testId="submit-primary-search">
        {t("common:searchButton")}
      </Button>
    </div>
  );
};
