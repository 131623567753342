import { tv } from "tailwind-variants";

export const hero = tv({
  slots: {
    root: ["relative", "isolate", "z-[2]"],
    image: [
      "max-md:aspect-[16/9]",
      "md:h-[540px]",
      "md:after:content-[]",
      "overflow-hidden",
      "after:inset",
      "after:bg-black/20",
      "after:absolute",
      "after:w-full",
      "after:aspect-[16/9]",
      "after:top-0",
    ],
    overlay: [
      "flex",
      "items-center",
      "justify-center",

      "h-full",
      "w-full",

      "bg-white",
      "rounded-t-lg",
      "-mt-4",
      "pt-6",
      "relative",
      "z-10",

      "md:absolute",
      "md:top-0",
      "md:left-0",
      "md:bg-black/20",
      "md:mt-0",
      "md:pt-0",
      "md:rounded-none",
    ],
    content: [
      "w-full",
      "max-w-lg",
      "sm:max-w-2xl",
      "md:max-w-4xl",
      "lg:max-w-5xl",
      "px-4",
      "md:-mt-6",
    ],
  },
});
