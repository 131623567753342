"use client";

import { memo } from "react";

import { PrimaryFiltersForm } from "@/components/PrimaryFiltersForm";

export const SearchBar = memo(function () {
  return (
    <div className="relative z-10 md:shadow-md md:z-10 md:h-14 md:bg-white md:rounded-sm md:border md:border-gray-150 md:mx-6">
      <PrimaryFiltersForm />
    </div>
  );
});

SearchBar.displayName = "SearchBar";
