/* eslint-disable jsx-a11y/no-autofocus */
import { IconArrowLeft } from "@plum/plum-ui-next";
import { Input } from "@plum/ui-core";

export const MobileFilterDrawer = ({ children, dialogRef, willClose, handleClose }) => {
  return (
    <dialog
      ref={dialogRef}
      className={`${
        willClose ? "translate-x-full will-close" : ""
      } h-full w-full max-w-none max-h-[unset] m-0 !mt-0 transition-transform duration-300 animate-slide-in-right`}
    >
      <div autoFocus tabIndex={-1}></div>
      <button className="ml-8 my-4" type="button" onClick={handleClose} tabIndex={-1}>
        <IconArrowLeft />
      </button>
      {children}
    </dialog>
  );
};
