import { memo } from "react";
import { useTranslation } from "@/i18n";

import { GuestsState } from "@/types/search";
import { Stepper, Text } from "@plum/ui-core";

const INFANTS_LIMIT = 5;
const PETS_LIMIT = 2;

export interface GuestFilterProps {
  initialValue: number;
  isDesktop?: boolean;
  onChange: (category: keyof GuestsState, value: number) => void;
  category: keyof GuestsState;
  forcedMin?: number;
}

export const enum MinMaxGuests {
  Min = 0,
  Max = 20,
}

export const GuestFilter = memo(function ({
  initialValue,
  onChange,
  category,
  forcedMin,
}: GuestFilterProps) {
  const { t } = useTranslation();
  const limits = {
    adults: MinMaxGuests.Max,
    children: MinMaxGuests.Max,
    infants: INFANTS_LIMIT,
    pets: PETS_LIMIT,
  };
  return (
    <div className="flex items-center justify-between w-full">
      <div>
        <Text _bold _scale="b-small">
          {t(`common:guests.${category}.label`)}
        </Text>
        <Text className="text-gray-700" _scale="b-tiny">
          {t(`common:guests.${category}.hint`)}
        </Text>
      </div>

      <div className=" md:w-auto">
        <Stepper
          min={forcedMin || MinMaxGuests.Min}
          max={limits[category]}
          initialValue={initialValue || MinMaxGuests.Min}
          renderLabel={(value) => {
            return value >= MinMaxGuests.Max ? `${value}+` : value;
          }}
          onChange={(value) => onChange(category, value)}
          dataTestid="guest-count-stepper"
        />
      </div>
    </div>
  );
});

GuestFilter.displayName = "GuestFilter";
