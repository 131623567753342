import { useState } from "react";
import qs from "query-string";

import { ComboboxItemFormat } from "@plum/plum-ui-next";

import { DatepickerType } from "@/types/search";

export interface StorageSearchParams {
  location: string;
  checkIn?: string;
  checkOut?: string;
  flexible?: string;
  guests: number | string;
  placeId: string;
  image?: string;
  datepickerType: DatepickerType;
}

export enum LocalStorageKeys {
  recentSearchQueries = "recentSearchQueries",
  recentSearchLocations = "recentSearchLocations",
}

export type LocalStorageKeysType = keyof typeof LocalStorageKeys;

function saveOnlyUnique<T extends Record<string, unknown>>(item: T, items: T[]) {
  return Array.from(new Set([qs.stringify(item, { skipNull: true }), ...items]));
}

// Get parsed localStorage recentLocations items or set stringified values
export const useRecentLocations = (): [
  ComboboxItemFormat[],
  (value: ComboboxItemFormat) => void,
] => {
  function getItemsFromStorage() {
    const items = localStorage.getItem(LocalStorageKeys.recentSearchLocations);

    if (!items) return [];

    return JSON.parse(items);
  }

  const [storedValue] = useState<ComboboxItemFormat[]>(() => {
    if (typeof window === "undefined" || !localStorage) {
      return [];
    }

    const items = getItemsFromStorage();

    return items.map(qs.parse).filter((item: ComboboxItemFormat) => !!(item.label && item.value));
  });

  const setValue = (item: ComboboxItemFormat) => {
    if (!item.label || !item.value) return;

    const items = getItemsFromStorage();

    window.localStorage.setItem(
      LocalStorageKeys.recentSearchLocations,
      JSON.stringify(saveOnlyUnique(item, items).slice(0, 6))
    );
  };

  return [storedValue, setValue];
};
