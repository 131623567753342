import { useCallback, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "@/i18n";
import { Text } from "@plum/ui-core";

import { DatepickerType, DatesState, DestinationState, GuestsState } from "@/types/search";

import { SSearchBarItem } from "../SearchBar/SSearchBar";

import { GuestsPopover } from "./GuestsPopover";
import { DatesPopover } from "./DatesPopover";
import { DestinationsPopover } from "./DestinationsPopover";

interface IDesktopFilters {
  datepickerType: DatepickerType | null;
  dates: DatesState;
  guests: GuestsState;
  destination: DestinationState;
}

export const DesktopFilters = () => {
  const { setValue, watch, handleSubmit } = useFormContext<IDesktopFilters>();
  const { datepickerType, dates, guests, destination } = watch();

  const [isDatesPopoverOpen, setDatesPopoverOpen] = useState(false);

  const destinationsFilter = useCallback(
    (destination) => {
      if (destination.placeId) {
        setDatesPopoverOpen(true);
      }
      setValue("destination", destination);
    },
    [setValue]
  );

  const datesFilter = useCallback(
    (dates) => {
      setValue("dates.calendar", dates.calendar);
      setValue("dates.flexible", dates.flexible);
      setValue("datepickerType", dates.type);
      setDatesPopoverOpen(false);
    },
    [setValue]
  );

  const guestsFilter = useCallback(
    (category: keyof GuestsState, value: number) => {
      if (category !== "adults" && value === 1 && !guests.adults) {
        // make sure we dont have 0 adults other guests
        setValue("guests.adults", 1);
      }
      setValue(`guests.${category}`, value);
    },
    [setValue, guests]
  );

  return (
    <ul className="grid grid-cols-3 items-center justify-between h-full relative">
      <SSearchBarItem>
        <DestinationsPopover destination={destination.location} onChange={destinationsFilter} />
      </SSearchBarItem>
      <SSearchBarItem>
        <DatesPopover
          calendar={dates.calendar}
          flexible={dates.flexible}
          type={datepickerType as DatepickerType}
          isOpenTrigger={isDatesPopoverOpen}
          onChange={datesFilter}
        />
      </SSearchBarItem>
      <SSearchBarItem>
        <GuestsPopover guests={{ ...guests }} onChange={guestsFilter} />
      </SSearchBarItem>
      <SearchButton />
    </ul>
  );
};

function SearchButton() {
  const { t } = useTranslation();

  return (
    <button
      type="submit"
      data-testid="desktop-confirm-search-button"
      className="absolute right-2 top-1/2 -translate-y-1/2 flex items-center h-10 px-6 rounded-full cursor-pointer overflow-hidden duration-200 ease-out-cubic [&]:bg-yellow-primary hover:bg-yellow-light"
    >
      <Text color="neutrals-9" _scale="b-small" _bold>
        {t("common:searchButton")}
      </Text>
    </button>
  );
}
