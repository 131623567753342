import { memo, useCallback, useRef, useState } from "react";
import { useTranslation } from "@/i18n";

import { Popover } from "@plum/ui-core";
import { LocationDetailsValues } from "@/components/LocationFilter";

import { ClearButton } from "@/components/ClearButton";

import { DestinationComboboxMenu, useDestinationCombobox } from "../DestinationCombobox";

import { ComboboxInput } from "../DestinationCombobox/Input";
import { Combobox } from "../DestinationCombobox/Combobox";

interface DestinationsPopoverProps {
  onChange: (locationDetails: LocationDetailsValues) => void;
  destination: string;
}

export const DestinationsPopover = memo(({ onChange, destination }: DestinationsPopoverProps) => {
  const { t } = useTranslation();

  const [isOpen, setOpen] = useState(false);

  const clearButtonRef = useRef<HTMLButtonElement>(null);
  const popoverContentRef = useRef(null);

  const inputId = "destinations-popover-input";

  const { inputRef, inputValue, comboboxSections, comboboxProps, clearSelection } =
    useDestinationCombobox({
      initialValue: destination,
      inputId,
      isOpen,
      onSelect: (label, placeId) => {
        onChange({ location: label, placeId });
      },
    });

  const handleKeyDown = useCallback(
    (e: { key: string; preventDefault: () => void; stopPropagation: () => void }) => {
      if (e.key === "Escape") {
        return setOpen(false);
      }
      if (e.key === "Enter") {
        const firstSuggestion = comboboxSections[0]?.items[0];
        if (inputValue.length >= 3 && firstSuggestion) {
          e.preventDefault();
          e.stopPropagation();
          onChange({
            location: firstSuggestion.label,
            placeId: firstSuggestion.value,
          });
          return;
        }
        return;
      }
    },
    [comboboxSections, inputValue, onChange]
  );

  return (
    <Popover.Root open={isOpen}>
      <Combobox {...comboboxProps} className="h-full w-full">
        <Popover.Trigger asChild>
          <button
            type="button"
            heap-ignore="true"
            className="flex relative w-full h-full items-center"
          >
            <ComboboxInput ref={inputRef}>
              <input
                className="w-full h-full text-b-sm text-gray-900 antialiased overflow-ellipsis font-sans pr-10 pl-4 cursor-pointer focus:outline-none focus:bg-gray-50 placeholder:text-gray-900 placeholder:focus:text-gray-500"
                placeholder={t("common:addDestination") as string}
                onClick={useCallback(() => {
                  if (!isOpen) {
                    return setOpen(true);
                  }
                }, [isOpen])}
                onFocus={useCallback(() => {
                  if (!isOpen) {
                    return setOpen(true);
                  }
                }, [isOpen])}
                onKeyDownCapture={handleKeyDown}
              />
            </ComboboxInput>
            {inputValue && isOpen && (
              <ClearButton
                ref={clearButtonRef}
                className="absolute right-3"
                onClick={clearSelection}
              />
            )}
          </button>
        </Popover.Trigger>
        <Popover.Content
          avoidCollisions={false}
          ref={popoverContentRef}
          align="start"
          className="w-[800px]"
          onOpenAutoFocus={useCallback(
            (e) => {
              e.preventDefault();

              setTimeout(() => {
                inputRef.current?.focus();
              }, 0);
            },
            [inputRef]
          )}
          onInteractOutside={useCallback(
            (e) => {
              const node = e.target as Node;

              if (clearButtonRef.current?.contains(node) || inputRef.current?.contains(node)) {
                e.preventDefault();
                return;
              }

              setOpen(false);
            },
            [inputRef]
          )}
        >
          <DestinationComboboxMenu sections={comboboxSections} />
        </Popover.Content>
      </Combobox>
    </Popover.Root>
  );
});

DestinationsPopover.displayName = "DestinationsPopover";
