import { memo, useCallback, useRef, useState } from "react";
import dynamic from "next/dynamic";
import { useTranslation } from "@/i18n";
import { IconArrowLeft } from "@plum/plum-ui-next";
import { IconStack, IconMagnifyingGlass, IconScribble1 } from "@plum/ui-icons";
import { DestinationState } from "@/types/search";
import { InputPreview } from "../InputPreview";
import { MobileFilterLoadingState } from "../MobileFilterLoadingState";
import { MobileFilterDrawer } from "../MobileFilterDrawer/MobileFilterDrawer";

const LocationFilterForm = dynamic(
  () => import("./Form").then((module) => module.LocationFilterForm),
  {
    ssr: false,
    loading: () => <MobileFilterLoadingState />,
  }
);
export interface LocationDetailsValues {
  location: string;
  placeId: string;
}

export interface LocationFilterProps {
  initialValue: DestinationState;
  onChange: (locationDetails: LocationDetailsValues) => void;
  dialogRef: React.RefObject<HTMLDialogElement>;
}

export const LocationFilter = memo(function ({
  initialValue,
  onChange,
  dialogRef,
}: LocationFilterProps) {
  const { t } = useTranslation();
  const [willClose, setWillClose] = useState(false);

  const buttonRef = useRef<HTMLButtonElement>(null);
  const newInputRef = useRef<HTMLInputElement>(null);
  const [locationDetails, setLocationDetails] = useState<DestinationState>(initialValue);

  const handleClose = useCallback(async () => {
    setWillClose(true);
    await setTimeout(() => {
      dialogRef.current?.close();
      setWillClose(false);
    }, 380);
  }, [dialogRef]);

  const handleChange = useCallback(
    async (location: string, placeId: string) => {
      onChange({ location, placeId });
      setLocationDetails({ location, placeId });
      await handleClose();
    },
    [handleClose, onChange]
  );

  return (
    <>
      <InputPreview
        onClick={() => {
          dialogRef.current?.showModal();
          newInputRef.current?.focus();
        }}
        ref={buttonRef}
        figure={
          <IconStack>
            <IconScribble1 />
            <IconMagnifyingGlass className="mr-4" />
          </IconStack>
        }
        value={locationDetails.location}
        placeholder={t("common:locationPlaceholder") as string}
        dataTestid="location-button-filter"
      />
      <MobileFilterDrawer dialogRef={dialogRef} willClose={willClose} handleClose={handleClose}>
        <LocationFilterForm
          initialValue={locationDetails.location}
          onSelect={handleChange}
          newInputRef={newInputRef}
        />
      </MobileFilterDrawer>
    </>
  );
});

LocationFilter.displayName = "LocationFilter";
