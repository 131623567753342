import { tv } from "tailwind-variants";

export const SDestinationComboboxItem = tv({
  base: [
    "flex",
    "items-center",
    "cursor-pointer",
    "py-4",
    "border-b",
    "border-b-gray-150",
    "relative",
    "z-0",
    "md:py-2",
    "md:border-b-0",
    "md:before:absolute",
    "md:before:-z-10",
    "md:before:top-0",
    "md:before:right-0",
    "md:before:h-full",
    "md:before:bg-yellow-lighter",
    "md:before:transition-opacity",
    "md:before:duration-100",
    "md:before:-left-6",
    "md:before:-mr-6",
    "md:before:opacity-0",
    "md:aria-selected:before:opacity-100",
  ],
});
