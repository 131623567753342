import { useFormContext } from "react-hook-form";
import { useTranslation } from "@/i18n";

import {
  Spacer,
  FormRadioInput,
  Grid,
  Box,
  IllustrationBeach,
  IllustrationFireplace,
  IllustrationLake,
  IllustrationVillage,
} from "@plum/plum-ui-next";
import { Text } from "@plum/ui-core";
import { DatesFilterFormState } from "../../types";

import { SFlexibleDatesPeriodOption } from "./Period.styles";

export function FlexibleDatesPeriod() {
  const { register, watch } = useFormContext<DatesFilterFormState>();

  const { t } = useTranslation();

  const selectedPeriod = watch("flexible.period");

  return (
    <Box css={{ paddingX: "$24" }}>
      <Text as="h3" _scale="b-medium" _bold>
        {t("common:flexibleDates.period.title")}
      </Text>
      <Spacer size={16} />
      <Grid columns={{ "@initial": 2, "@md": 4 }} gap={16} placeholder="">
        {[
          {
            title: t("common:flexibleDates.period.options.weekend.title"),
            description: t("common:flexibleDates.period.options.weekend.description"),
            illustration: IllustrationVillage,
            value: "weekend",
            dataTestid: "filter-flexible-weekend",
          },
          {
            title: t("common:flexibleDates.period.options.week.title"),
            description: t("common:flexibleDates.period.options.week.description"),
            illustration: IllustrationFireplace,
            value: "week",
          },
          {
            title: t("common:flexibleDates.period.options.twoWeeks.title"),
            description: t("common:flexibleDates.period.options.twoWeeks.description"),
            illustration: IllustrationBeach,
            value: "twoWeeks",
          },
          {
            title: t("common:flexibleDates.period.options.month.title"),
            description: t("common:flexibleDates.period.options.month.description"),
            illustration: IllustrationLake,
            value: "month",
          },
        ].map(({ title, description, illustration: Illustration, value }) => (
          <label
            className={SFlexibleDatesPeriodOption({ selected: selectedPeriod === value })}
            key={value}
          >
            <Illustration
              css={{ height: "$80", width: "$80" }}
              scribble={selectedPeriod === value}
            />
            <Text color="neutrals-9" _bold testId="flexible-period">
              {title}
            </Text>
            <Text _scale="b-tiny">{description}</Text>
            <Spacer size={8} />
            <FormRadioInput label="" noLabel value={value} {...register("flexible.period")} />
          </label>
        ))}
      </Grid>
    </Box>
  );
}
