import { GuestsState } from "@/types/search";

import { GuestFilter } from "../GuestFilter";
interface GuestFilterGroupProps {
  guests: GuestsState;
  onChange: (category: keyof GuestsState, value: number) => void;
  isDesktop?: boolean;
}
export const GuestFilterGroup: React.FC<React.PropsWithChildren<GuestFilterGroupProps>> = ({
  guests,
  onChange,
}: GuestFilterGroupProps) => {
  const guestCategories = Object.keys(guests) as Array<keyof GuestsState>;
  const nonAdultGuestsExist = guests.children + guests.infants + guests.pets > 0;
  return (
    <div className="grid grid-cols-1 divide-y divide-gray-150">
      {guestCategories.map((category) => (
        <div key={category} className="pb-4 md:pb-2 first:pt-0 pt-4 md:pt-2 last:pb-0">
          <GuestFilter
            forcedMin={category === "adults" ? Number(nonAdultGuestsExist) : undefined}
            initialValue={guests[category]}
            onChange={onChange}
            category={category}
          />
        </div>
      ))}
    </div>
  );
};
