import { FlexibleDatesPeriod } from "./Period";
import { FlexibleDatesMonths } from "./Months";

export function FlexibleDates() {
  return (
    <div className="flex flex-col space-y-6">
      <FlexibleDatesPeriod />
      <FlexibleDatesMonths />
    </div>
  );
}
