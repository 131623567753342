import React, { useCallback, useEffect, useMemo, useRef } from "react";
import { Trans, useTranslation } from "@/i18n";
import { FormProvider, useForm, useFormContext } from "react-hook-form";
import { Button } from "@plum/ui-core";
import { Grid, GridItem, Spacer, Switch, SwitchOption, Link } from "@plum/plum-ui-next";

import { DatesFilterFormState } from "./types";
import { CalendarDates, FlexibleDates, FlexibleCheckIn } from "./components";
import { SDatesFilterContent, SFooterWrap } from "./Form.styles";

export const defaultFormState: DatesFilterFormState = {
  calendar: {
    checkIn: undefined,
    checkOut: undefined,
    flex: null,
  },
  flexible: {
    period: null,
    months: [],
  },
  type: "calendar",
};

export interface DatesFilterFormProps {
  initialValue: DatesFilterFormState;
  isDesktop?: boolean;
  onChange?: (data: DatesFilterFormState) => void;
  onConfirm?: () => void;
}

export const DatesFilterForm = ({
  initialValue,
  isDesktop,
  onChange,
  onConfirm,
}: DatesFilterFormProps) => {
  const methods = useForm<DatesFilterFormState>({
    defaultValues: defaultFormState,
  });

  const onReset = useCallback(() => {
    onChange?.(methods.getValues());
  }, [methods, onChange]);

  const { type, calendar, flexible } = methods.watch();

  const hasCalendarDates = useMemo(() => {
    return type === "calendar" && !!calendar.checkIn && !!calendar.checkOut;
  }, [calendar.checkIn, calendar.checkOut, type]);

  const hasFlexibleDates = useMemo(() => {
    return type === "flexible" && !!flexible.period && !!flexible.months;
  }, [flexible.period, flexible.months, type]);

  const onSubmit = useCallback(
    (state: DatesFilterFormState) => {
      if (type === "calendar") {
        methods.resetField("flexible.period");
        methods.resetField("flexible.months");
      } else {
        methods.resetField("calendar.checkIn");
        methods.resetField("calendar.checkOut");
      }
      onChange?.(state);
      onConfirm?.();
    },
    [onChange, onConfirm, type, methods]
  );

  useEffect(() => {
    methods.setValue("calendar", initialValue.calendar);
    methods.setValue("flexible", initialValue.flexible);

    if (initialValue.type) methods.setValue("type", initialValue.type);
  }, [initialValue.calendar, initialValue.type, initialValue.flexible, methods]);

  // Use a ref for the onChange function to ensure we don't cause an infinite loop when
  // calling it in the parent component
  // https://stackoverflow.com/a/64337108/4474075
  const onChangeRef = useRef(onChange);

  useEffect(() => {
    onChangeRef.current = onChange;
  }, [onChange]);

  useEffect(() => {
    if (!isDesktop || !(hasCalendarDates || hasFlexibleDates)) return;

    onChangeRef.current?.({
      type,
      calendar: {
        checkIn: calendar.checkIn,
        checkOut: calendar.checkOut,
        flex: calendar.flex,
      },
      flexible: {
        period: flexible.period,
        months: flexible.months,
      },
    });
  }, [
    type,
    hasCalendarDates,
    hasFlexibleDates,
    calendar.checkIn,
    calendar.checkOut,
    calendar.flex,
    flexible.period,
    flexible.months,
    isDesktop,
  ]);

  return (
    <FormProvider {...methods}>
      <Grid rows="max-content 1fr max-content" css={{ height: "100%" }} placeholder="">
        <GridItem placeholder="" css={{ width: "100%" }}>
          <DatesFilterHeader />
        </GridItem>
        <DatesFilterContent isDesktop={isDesktop} />
        {!isDesktop && (
          <DatesFilterFooter
            onReset={onReset}
            onSubmit={(e) => {
              e.stopPropagation();

              methods.handleSubmit(onSubmit)(e);
            }}
          />
        )}
      </Grid>
    </FormProvider>
  );
};

function DatesFilterHeader() {
  const { t } = useTranslation();

  const { watch, setValue } = useFormContext<DatesFilterFormState>();

  const type = watch("type");

  return (
    <div className="px-6 md:px-0 md:max-w-[360px] m-auto">
      <Switch name="type" value={type} onValueChange={(v) => setValue("type", v)}>
        <SwitchOption value="calendar">{t("common:datesSelector.switch.calendar")}</SwitchOption>
        <SwitchOption value="flexible">
          <Trans i18nKey="common:datesSelector.switch.flexible" />
        </SwitchOption>
      </Switch>
      <Spacer size={{ "@initial": 24, "@md": 12 }} />
    </div>
  );
}

function DatesFilterContent({ isDesktop }: { isDesktop?: boolean }) {
  const { watch } = useFormContext<DatesFilterFormState>();

  const type = watch("type");

  return (
    <>
      <div key={type} className={SDatesFilterContent({ type })}>
        {type === "calendar" ? <CalendarDates isDesktop={isDesktop} /> : <FlexibleDates />}
      </div>
      {type === "calendar" && <FlexibleCheckIn />}
    </>
  );
}

function DatesFilterFooter({ onReset, onSubmit }) {
  const { t } = useTranslation();

  const { setValue, watch } = useFormContext<DatesFilterFormState>();

  const { calendar, flexible, type } = watch();

  const isCalendar = type === "calendar";

  const hasDates = useMemo(
    () => calendar.checkIn && calendar.checkOut,
    [calendar.checkIn, calendar.checkOut]
  );
  const hasFlexible = useMemo(
    () => !!flexible.period && flexible.months.length > 0,
    [flexible.period, flexible.months]
  );

  const shouldShowFooter = useMemo(
    () => (isCalendar && hasDates) || (!isCalendar && hasFlexible),
    [isCalendar, hasDates, hasFlexible]
  );

  if (!shouldShowFooter) return null;

  return (
    <div className={SFooterWrap() + " items-center justify-between"}>
      <Link
        as="button"
        type="button"
        intent="detail"
        underline
        onClick={() => {
          if (isCalendar) {
            setValue("calendar", defaultFormState.calendar);
          } else {
            setValue("flexible", defaultFormState.flexible);
          }

          onReset();
        }}
      >
        {t("common:clearSelection")}
      </Link>
      <Button
        type="button"
        className="px-12 rounded-full"
        disabled={type === "flexible" && !hasFlexible}
        testId="apply-dates"
        onClick={onSubmit}
      >
        {t("common:applyButton")}
      </Button>
    </div>
  );
}
