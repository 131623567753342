import { useFormatPrice } from "@plum/plum-ui-next";

import { getSearchUrlForFacet } from "@/utils/search";

import { useAppContext } from "@/data/AppProvider";
import { useUserContext } from "@/data/UserProvider";

const getDestinationNameWithApostrophe = (destinationName: string | undefined): string => {
  return destinationName ? `${destinationName}'${!destinationName.endsWith("s") ? "s" : ""}` : "";
};

/**
 * Translates a string with brackets `[]` and curly brackets `{}` format for placeholders.
 * Used for contenful and other manual sources of copy.
 *
 * Curly brackets are used for placeholders where the values are in the URL params.
 *
 * Example:
 * `Hello [name] to [place]` given an object with
 * `{name: string, place: string}`
 *
 * Example:
 * https://www.plumguide.com/d/los-angeles/airbnb?title=true&titleId=example&propertyType=chalet
 * `Find the best {propertyType} in [destination]` given an object with
 * `{propertyType: string, destination: string}`
 */
const tCustom = (values) => (copy: string | undefined) => {
  if (!copy) return null;

  Object.keys(values).forEach((k) => {
    copy = copy!
      .replace(new RegExp(`\\[${k}\\]`, "g"), values[k])
      .replace(new RegExp(`\\{${k}\\}`, "g"), values[k]);
  });

  return copy;
};

export const useCityTranslation = () => {
  const { canonical, facetName, searchTotal } = useAppContext();
  const [{ user }] = useUserContext();

  const canonicalDestinationName = canonical?.name;

  const searchUrl = getSearchUrlForFacet();

  const address = canonical?.address;
  const placeId = canonical?.placeId;

  const hasCity = canonicalDestinationName && address && address.length;

  const lowestPrice = 98;

  const { priceToString } = useFormatPrice({
    currencyCode: user.currencyCode,
    locale: user.language,
    price: lowestPrice,
  });

  const tProps = {
    context: !hasCity ? "noCity" : canonicalDestinationName.endsWith("s") ? "apostrophe" : "",
    city: canonicalDestinationName,
    destination: canonicalDestinationName,
    destination_apostrophe: getDestinationNameWithApostrophe(canonicalDestinationName),
    address,
    placeId,
    facetName,
    price: priceToString,
    location: canonicalDestinationName,
    listingCount: searchTotal && searchTotal < 5 ? "best" : searchTotal,
    searchUrl,
  };

  return {
    tProps,
    tCustom: tCustom(tProps),
    tCustomTokens: tCustom,
  };
};
