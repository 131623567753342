/* eslint-disable jsx-a11y/no-autofocus */
import React, { memo, useCallback, useMemo, useState } from "react";
import dynamic from "next/dynamic";
import { useTranslation } from "@/i18n";
import parseISO from "date-fns/parseISO";

import { Spacer } from "@plum/plum-ui-next";

import { IconStack, IconCalendar, IconScribble1 } from "@plum/ui-icons";

import { DateFormats, formatDate } from "@/utils/dates";
import { SFlexibleDatesPill } from "@/components/FlexibleDatesPill";
import { InputPreview } from "@/components/InputPreview";
import { DatesFilterFormState } from "./types";
import { MobileFilterDrawer } from "../MobileFilterDrawer/MobileFilterDrawer";
import { MobileFilterLoadingState } from "../MobileFilterLoadingState";

const DatesFilterForm = dynamic(() => import("./Form").then((module) => module.DatesFilterForm), {
  ssr: false,
  loading: () => <MobileFilterLoadingState />,
});

export interface DatesFilterProps {
  initialValue: DatesFilterFormState;

  onChange: (data: DatesFilterFormState) => void;
  dialogRef: React.RefObject<HTMLDialogElement>;
}

export const DatesFilter = memo(function ({ initialValue, onChange, dialogRef }: DatesFilterProps) {
  const [{ calendar, flexible, type }, setState] = useState<DatesFilterFormState>(initialValue);

  const { t } = useTranslation();

  const hasDates = useMemo(() => calendar.checkIn && calendar.checkOut, [calendar]);

  const hasFlexible = useMemo(
    () => !!flexible.period && flexible.months.length > 0,
    [flexible.period, flexible.months]
  );

  const handleClose = useCallback(async () => {
    setWillClose(true);
    await setTimeout(() => {
      dialogRef.current?.close();
      setWillClose(false);
    }, 300);
  }, [dialogRef]);

  const handleChange = useCallback(
    (data: DatesFilterFormState) => {
      setState(data);
      onChange(data);
    },
    [onChange]
  );
  const [willClose, setWillClose] = useState(false);

  return (
    <>
      <InputPreview
        onClick={() => dialogRef.current?.showModal()}
        figure={
          <IconStack>
            <IconScribble1 />
            <IconCalendar className="mr-4" />
          </IconStack>
        }
        placeholder={t("common:addDates") as string}
        value={
          type === "calendar" && hasDates ? (
            <span>
              {`${formatDate(calendar.checkIn as Date, DateFormats.PRETTY_NO_YEAR)} - ${formatDate(
                calendar.checkOut as Date,
                DateFormats.PRETTY_NO_YEAR
              )}`}
              {calendar.flex && (
                <>
                  <Spacer horizontal size={8} inline />
                  <SFlexibleDatesPill>±{calendar.flex}</SFlexibleDatesPill>
                </>
              )}
            </span>
          ) : type === "flexible" && hasFlexible ? (
            <span>
              {t(`common:flexibleDates.period.options.${flexible.period}.preview`, {
                months: flexible.months
                  .map((month) => formatDate(parseISO(month), DateFormats.MONTH_SHORT))
                  .join(", "),
              })}
            </span>
          ) : null
        }
        dataTestid="dates-button-filter"
      />
      <MobileFilterDrawer dialogRef={dialogRef} willClose={willClose} handleClose={handleClose}>
        <div className="h-[92.5%]">
          <DatesFilterForm
            initialValue={{
              calendar,
              flexible,
              type,
            }}
            onChange={handleChange}
            onConfirm={handleClose}
          />
        </div>
      </MobileFilterDrawer>
    </>
  );
});

DatesFilter.displayName = "MobileDatesFilter";
