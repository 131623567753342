import React from "react";
import { useTranslation } from "@/i18n";

import { IconCross } from "@plum/ui-icons";
import { tv } from "tailwind-variants";

const SClearButton = tv({
  base: [
    "flex",
    "items-center",
    "justify-center",
    "h-6",
    "w-6",
    "cursor-pointer",
    "pointer-events-auto",
    "rounded-full",
    "bg-gray-100", // Assuming "$neutrals-2" maps to a color in your palette
  ],
});
type ClearButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement>;

export const ClearButton = React.forwardRef<HTMLButtonElement, ClearButtonProps>(
  ({ children, className, ...props }, ref) => {
    const { t } = useTranslation();

    return (
      <button
        className={SClearButton({ className })}
        ref={ref}
        type="button"
        aria-label={t("common:clearSelection") as string}
        {...props}
      >
        {children || <IconCross _size="xs" />}
      </button>
    );
  }
);

ClearButton.displayName = "ClearButton";
