import { tv } from "tailwind-variants";

export const SFooterWrap = tv({
  base: "flex py-4 px-6 md:p-0",
  variants: {
    divider: {
      true: "border-t border-gray-100",
    },
  },
});

export const SDatesFilterContent = tv({
  base: [
    "border-t",
    "border-b",
    "border-t-gray-150",
    "border-b-gray-150",
    "overflow-x-hidden",
    "overflow-y-auto",
    "md:px-0",
    "md:max-w-full",
    "md:mx-auto",
    "md:border-none",
  ],
  variants: {
    type: {
      flexible: ["py-6"],
      calendar: ["py-0"],
    },
  },
});
