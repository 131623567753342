import { useMemo } from "react";

import { FlexiblePeriod } from "@/types/graphql-global-types";
import { DatepickerType } from "@/types/search";
import { DateValue } from "@plum/plum-ui-next";

export const useHasCalendarDates = ({
  datepickerType,
  checkIn,
  checkOut,
}: {
  datepickerType: DatepickerType;
  checkIn: DateValue;
  checkOut: DateValue;
}): boolean => {
  return useMemo(() => {
    return datepickerType === "calendar" && !!checkIn && !!checkOut;
  }, [checkIn, checkOut, datepickerType]);
};

export const useHasFormFlexibleDates = ({
  datepickerType,
  period,
  months,
}: {
  datepickerType: DatepickerType;
  period: FlexiblePeriod | null;
  months: string[];
}): boolean => {
  return useMemo(() => {
    return datepickerType === "flexible" && !!period && months.length > 0;
  }, [datepickerType, months, period]);
};
