import { useFormContext } from "react-hook-form";
import { useEffect } from "react";

import { Datepicker } from "@plum/plum-ui-next";

import { DatesFilterFormState } from "../../types";

export function CalendarDates({ isDesktop }: { isDesktop?: boolean }) {
  const { register, watch, setValue } = useFormContext<DatesFilterFormState>();

  const checkIn = watch("calendar.checkIn");
  const checkOut = watch("calendar.checkOut");

  useEffect(() => {
    register("calendar.checkIn");
    register("calendar.checkOut");
  }, [register]);

  return (
    <Datepicker
      defaultMonthsInView={7} // this seems to be the maximum we can display without going above 200ms of INP with a 4x CPU slowdown
      direction={isDesktop ? "normal" : "vertical"}
      {...(isDesktop ? { numberOfMonths: 2 } : {})}
      isOpen
      disabledDays={{ before: new Date() }}
      value={{ from: checkIn, to: checkOut }}
      clearHighlightedOnBlur
      onDatesChange={({ from, to }) => {
        setValue("calendar.checkIn", from);
        setValue("calendar.checkOut", to);
      }}
    />
  );
}
