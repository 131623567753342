import { gql } from "@apollo/client";

export const GET_SUGGESTIONS = gql`
  query GetSuggestions($term: String!) {
    suggestionsByTerm(term: $term) {
      total
      places {
        placeId: id
        title
        latitude
        longitude
        matchedSubstrings {
          length
          offset
        }
      }
    }
  }
`;

export const GET_POPULAR_SUGGESTIONS = gql`
  query GetPopularSuggestions($countryCode: String!) {
    popularSuggestionsByCountryCode(countryCode: $countryCode) {
      total
      places {
        placeId: id
        title
        latitude
        longitude
      }
    }
  }
`;
export const GET_FACETS_BY_CANONICAL = gql`
  query FacetsByCanonical($facet: String!, $canonical: String!) {
    doesLocationSupportsFacet(facet: $facet, canonical: $canonical)
  }
`;

export const GET_CATALOGUE_WITH_FACETS = gql`
  query GetCatalogueWithFacets(
    $canonical: String
    $depth: Int
    $minListingCount: Int
    $bypass404: Boolean
    $bypassContentfulFilters: Boolean
  ) {
    catalogueByCanonical(
      canonical: $canonical
      depth: $depth
      minListingCount: $minListingCount
      bypass404: $bypass404
      bypassContentfulFilters: $bypassContentfulFilters
    ) {
      placeId
      longName
      listingCount
      canonicalSlug
      title
      facets {
        name
        slug
      }
    }
  }
`;

export const GET_FULL_CATALOGUE_SITEMAP = gql`
  query GetFullCatalogue($minListingCount: Int, $applyListingCountRestriction: Boolean) {
    fullCatalogueSitemap(
      minListingCount: $minListingCount
      applyListingCountRestriction: $applyListingCountRestriction
    ) {
      path
      searchUrl
    }
  }
`;
